var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('responsive-menu',{ref:"menu",attrs:{"bind":_vm.selectedItem,"title":_vm.selectedItemName,"opcoes":_vm.menuOptions},on:{"click:item":function (action) { return _vm.$emit(("click:" + action), _vm.selectedItem); }}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"dense":"","fixed-header":"","options":_vm.pageOptions,"server-items-length":_vm.totalElements,"disable-sort":true,"hide-default-header":_vm.$vuetify.breakpoint.mobile,"footer-props":{
    disableItemsPerPage: true,
    itemsPerPageOptions: [_vm.itemsPerPage],
  },"items":_vm.elements,"loading":_vm.loading},on:{"update:options":function($event){_vm.pageOptions=$event},"click:row":_vm.onClickRow,"contextmenu:row":function($event){$event.preventDefault();return _vm.showMenu.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
  var item = ref.item;
return [_c('strong',[_vm._v("#"+_vm._s(item.id))])]}},{key:"item.tpAluguel",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.tpAluguel === 'M' ? 'Mensal' : 'Avulso'))])]}},{key:"item.idCliente_nome",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.idCliente > 0 ? item.idCliente_nome : "GRUPO: "+item.idGrupoLocacao_nome)))])]}},{key:"item.idFatura",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dadosfatura(item)))])]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.status === 'A' ? 'Ativo' : 'Inativo'))])]}},{key:"item.valAluguel",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v("R$ "+_vm._s(item.valAluguel))])]}},{key:"item.dias",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.tpAluguel === 'M' ? item.dias.map(function (d) { return d.descricao; }).join(', ') : '--'))])]}},{key:"item.data",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.data,'DD/MM/YYYY')))])]}},{key:"item.edit",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.preventDefault();return _vm.showMenu($event, item)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}},(_vm.$vuetify.breakpoint.mobile)?{key:"body",fn:function(ref){
  var items = ref.items;
  var isSelected = ref.isSelected;
  var select = ref.select;
return [_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((items),function(item){return _c('v-list-item',{key:item.id,staticClass:"list-item",on:{"click":function($event){return _vm.showMenu($event, item)}}},[_c('v-list-item-avatar',[_vm._v(" #"+_vm._s(item.id)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s((item.idCliente > 0 ? item.idCliente_nome : "GRUPO: "+item.idGrupoLocacao_nome))+" ")]),_c('v-list-item-subtitle',[_vm._v(" Locação "+_vm._s(item.tpAluguel === 'M' ? 'Mensal no(s) dia(s): ' + item.dias.map(function (d) { return d.descricao; }).join(', ') : 'Avulso')+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.idLocacao_descricao)+" - R$ "+_vm._s(item.valAluguel)+" - "+_vm._s(item.status === 'A' ? 'Ativo' : 'Inativo')+" ")])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1)}),1)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }